import { Pipe, PipeTransform } from '@angular/core';
import { DataUtil } from '@app/helper';

@Pipe({
  name: 'round',
  standalone: true
})
export class RoundPipe implements PipeTransform {

  transform(int: number, places: number = 0): unknown {
    return DataUtil.roundToDecimal(int, places);
  }

}
