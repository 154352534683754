import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'imgThumb'
})
export class ImgThumbPipe implements PipeTransform {

  transform(url: string, suffix = '-thumb'): string {
    if (typeof url !== 'string') return url ?? '';

    const lastDotIndex = url.lastIndexOf('.');
    return `${url.slice(0, lastDotIndex)}${suffix}${url.slice(lastDotIndex)}`;
  }
}
