export class ArrayUtil {

  public static getMax(numbers: number[]): number {
    return Math.max(...numbers);
  }

  public static getSum(numbers: number[]): number {
    return numbers.reduce((total, num) => total + num, 0);
  }

}
