import { AsyncPipe, NgClass } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { BehaviorSubject, catchError, EMPTY, lastValueFrom, Observable, tap } from 'rxjs';
import { environment } from '@environment';
import { NotificationHandler } from '@app/common/helper/notification.handler';
import { DataUtil } from '@app/helper';
import { DragDropDirective } from './drag-drop.directive';
import { DebounceInputDirective } from '@app/common/directives';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'file-upload',
  standalone: true,
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  imports: [
    AsyncPipe,
    DebounceInputDirective,
    DragDropDirective,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgClass,
  ],
})
export class FileUploadComponent {
  private http = inject(HttpClient);

  @Input() disabled = false;
  @Input() acceptTypes: string[] = [];
  @Input() multiSelect = false;
  @Input() showSelected = true;
  @Input() allowUrl = false;

  @Output() preloadInProgress = new EventEmitter<boolean>();
  @Output() fileSelected = new EventEmitter<File[]>();
  urlInput: string | undefined = undefined;
  get fileTypes(): string {
    return this.acceptTypes.map(e => `.${e}`).join(',')
  }

  readonly urlProcessing$ = new BehaviorSubject(false);
  files: File[] = [];

  dropFile(files: File[]) {
    this.handleFileInternal(files);
  }

  selectFile(event: Event) {
    if (event.target && 'files' in event.target) {
      this.handleFileInternal(event.target['files'] as File[] || [], event);
    }
  }

  async handleUrlChange(imageUrl: string): Promise<void> {
    if (!imageUrl) return;

    const url = `${environment.api.host}/images/fetch?url=${encodeURIComponent(imageUrl)}`;
    try {
      this.preloadInProgress.emit(true);
      const blob = await lastValueFrom(this.fetchImage(url));
      this.handleFileInternal(
        [new File([blob], DataUtil.getFileName(imageUrl) || 'noname.jpg', { type: 'image/jpeg' })]
      );
    } catch (error) {
      this.preloadInProgress.emit(false);
    }
  }

  private fetchImage(url: string): Observable<Blob> {
    this.urlProcessing$.next(true);
    return this.http.get(url, { responseType: 'blob' }).pipe(
      tap(() => this.urlProcessing$.next(false)),
      catchError(error => {
        this.urlProcessing$.next(false);
        NotificationHandler.handleError('Upload failed: The URL you provided does not support uploading. Please provide a valid URL.');
        console.error(error);
        return EMPTY;
      }),
    );
  }

  private clearFileInput(event: Event) {
    if (event?.target as HTMLInputElement) {
      (event.target as HTMLInputElement).value = '';
    }
  }

  private handleFileInternal(files: File[], event?: Event) {
    for (const file of files) {
      if (this.acceptTypes.length) {
        const formtAllowed = this.acceptTypes.some(type => file.name.endsWith(type));
        if (!formtAllowed) {
          NotificationHandler.handleError(`File ${file.name} has unsupported image format. Supported formats: ${this.acceptTypes}`);
          break;
        }
      }

      if (!this.multiSelect && this.files.length >= 1) {
        this.files = [];
        this.files.push(file);
      } else {
        this.files.push(file);
      }
      this.urlInput = undefined;
      this.fileSelected.emit(this.files);
    }
    if (event) this.clearFileInput(event);
  }

  deleteAttachment(index: number) {
    this.files.splice(index, 1);
    this.fileSelected.emit(this.files);
  }
}
